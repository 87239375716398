<template>
    <div class="page">
        <headers />
        <el-card class="box-card">
            <p style="color: red; padding: 50px 0 20px;line-height: 30px; text-indent: 2em; width: 1000px;margin: auto;">
                尊敬的学者，您现在进入的是春城译著数据库，本数据库所提供的每一部英文原版著作我们均已经确定、翻译出版译著的原版图书可以获得中文版权。你只要输入和领域匹配的专业关键词，就能检索到你需要的信息及估算报价，你只要把该书的图片信息发给我们，我们将安排专业编辑和你对接具体出版事宜。
            </p>
            <div class="search-section">
                <div class="search-box">
                    <span>书名：</span>
                    <el-input style="width: 300px;" class="search-content" @keypress.enter.native="search"
                        v-model="searchValue" placeholder="请输入书名"></el-input>
                </div>

                <!-- <div class="search-box">
                    <span>数据库：</span>
                    <el-select class="search-content" v-model="selectValue" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div> -->

                <div class="search-box">
                    <el-button class="button" type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    <el-button class="button" type="primary" icon="el-icon-refresh-right" @click="resetting">重置</el-button>
                </div>

            </div>
            <el-table :data="tableData" style="width: 100%" border v-loading="loading" element-loading-text="数据库庞大，请耐心等待。">
                <el-table-column prop="title_en" label="原著书名" min-width="150px">
                    <!-- <template slot-scope="scope">
                        <a :href="scope.row.bookHref" target="_blank">{{ scope.row.title_en }}</a>
                    </template> -->
                </el-table-column>
                <el-table-column prop="title_zh" label="中文书名" min-width="150px">
                </el-table-column>
                <el-table-column prop="pageNumber" label="页数" min-width="150px">
                </el-table-column>
                <el-table-column prop="time" label="时间" min-width="150px">
                </el-table-column>
                <el-table-column prop="versionNumber" label="版本号" min-width="150px">
                </el-table-column>
                <el-table-column prop="publisher" label="出版社" min-width="150px">
                </el-table-column>
                <el-table-column prop="authors" label="作者" min-width="150px">
                </el-table-column>
                <el-table-column prop="action" label="操作" min-width="150px">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">查看图文</el-button>
                    </template>
                </el-table-column>
            </el-table>

        </el-card>
        <el-dialog title="图文" :visible.sync="dialogVisible" width="800px" :before-close="handleClose">
            <div class="bookInfor" ref="content" id="content" v-loading="loadingDialog" element-loading-text="正在翻译中，请耐心等待。">
                <div class="inforBox">
                    <div class="inforText" style="width: 33%;">
                        <div class="inforItem">
                            <span class="label">原著:</span>
                            <span>《{{ record.title_en }}》</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">出版社:</span>
                            <span>{{ record.publisher }}</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">作者:</span>
                            <span> {{ record.authors }}</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">版本号:</span>
                            <span>{{ record.versionNumber }}</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">时间:</span>
                            <span>{{ record.time }}</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">页数:</span>
                            <span>{{ record.pageNumber }}</span>
                        </div>
                        <!-- <div class="inforItem">
                            <span class="label">字数:</span>
                            <span>{{ record.pageNumber }}</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">图片:</span>
                            <span>{{ record.pageNumber }}</span>
                        </div> -->


                    </div>
                    <div class="bookFace" ref="bookFace" style="width: 30%;">
                        <img ref="myImage" :src="getImage(record)" alt="">
                    </div>
                    <div class="inforText" style="width: 33%;">

                        <div class="inforItem">
                            <span class="label">译著:</span>
                            <span>《{{ znData.title_zh }}》</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">出版社:</span>
                            <span>待定</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">参与者:</span>
                            <span> 主审、主译、副主译、译者</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">定价:</span>
                            <span>{{ record.price }}元</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">装帧:</span>
                            <span>精装铜版纸彩色印刷</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">费用含:</span>
                            <span>版权、审读、出版发行等</span>
                        </div>
                        <div class="inforItem">
                            <span class="label">本书收:</span>
                            <span>{{ record.cost }}万出版费用、发行另计</span>
                        </div>
                    </div>
                </div>

                <div class="introduction">
                    <!-- <div class="inforItem">
                        <p class="label">简介:</p>
                        <p>{{ record.abstractText_en }}</p>
                    </div> -->
                    <div class="inforItem">
                        <p class="label" v-if="znData.abstractText_zh != null && znData.abstractText_zh != ''">简介:</p>
                        <p>{{ znData.abstractText_zh }}</p>
                    </div>

                    <el-divider style="color: #fff;" />

                    <!-- <div class="inforItem">
                        <p class="label">目录:</p>
                        <p v-for="(item, index) in record.catalogue_en" :key="index">{{ item }}</p>
                    </div> -->
                    <div class="inforItem">
                        <p class="label" v-if="znData.catalogue_zh.length > 0">目录:</p>
                        <p v-for="(item, index) in znData.catalogue_zh" :key="index">{{ item }}</p>
                    </div>
                    <!-- <el-divider style="color: #fff;" />
                    <div class="inforItem">
                        <p class="label">关于编者:</p>
                        <p ></p>
                    </div> -->
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="createImage(record)">生成图片</el-button>
            </span>
        </el-dialog>
        <div class="kf_button" @click="open53">
            在线咨询
            <img src="../assets/img/kf.png" alt="">
        </div>
    </div>
</template>
<script defer="true" src="http://kf.usp-pb.com:80/im/0xuapa.html"></script>
<script>
import { get, post } from '../utils/http'
import Headers from '../components/Headers.vue'//引入组件
import html2canvas from 'html2canvas'
export default {
    components: {//进行注册才能用
        Headers,
    },
    data() {
        return {
            options: [{
                value: '0',
                label: 'Springer'
            }, {
                value: '1',
                label: 'Elsevier'
            }],
            searchValue: '',
            selectValue: '0',
            tableData: [],
            loading: false,
            pageNo: 1,
            dialogVisible: false,
            record: {},
            dataUrl: '',
            // catalogue_zh:[],
            enData:{},
            znData:{catalogue_zh:[]},
            loadingDialog:false
        }
    },
    mounted(){
        // this.search()
    },
    methods: {
        getImage(record) {
            let url = "https://www.springcity.top:9999/jeecg-oa/dataManage/books/proxyImage?imageUrl=" + record.image
            return url;
        },
        open53() {
            window.open("http://kf.usp-pb.com/im/text/0xuapa.html", '_blank', "height=600,width=800, top=300, left=0, toolbar=no, menubar=no,location=no")
        },
        search() {
            if (this.searchValue == null || this.searchValue == "") {
                this.$message.error("请输入书名")
                return;
            }
            console.log(this.selectValue,"this.selectValue")
            this.loading = true
            get("/jeecg-oa/dataManage/books/list?condition=" + this.searchValue + "&source=0&pageNo=" + this.pageNo+"&translate=1").then((res) => {
                if (res.success) {
                    this.tableData = res.result
                    this.tableData.forEach(item=>{
                        var arr = item.pageNumber.split(",");
                        var num = parseInt(arr[1]);
                        if(num<200){
                            item.price=158
                            item.cost=5.8
                        }else if(200<=num&&num<300){
                            item.price=208
                            item.cost=6.8
                        }else if(300<=num){
                            item.price=258
                            item.cost=7.8
                        }else{
                            item.price=258
                            item.cost=7.8
                        }
                    })
                } else {
                    this.$message.error(res.result.message)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        resetting() {
            this.searchValue = ''
            this.selectValue = '0'
            this.tableData = []
        },
        
        handleClick(scope) {
            this.dialogVisible = true
            this.record = scope 
            if(this.record.abstractText_en&&this.record.abstractText_zh==null){
                this.loadingDialog = true
                this.enData.abstractText_en=scope.abstractText_en
                this.enData.catalogue_en=scope.catalogue_en
                this.enData.title_en=scope.title_en
                post("/jeecg-oa/dataManage/books/translate", this.enData).then((res) => {
                    if (res.success) {
                        this.$message.success("翻译完成")
                        this.znData=res.result
                        scope.abstractText_zh=this.znData.abstractText_zh
                        scope.catalogue_zh=this.znData.catalogue_zh
                        scope.title_zh=this.znData.title_zh
                        this.enData={}
                        this.loadingDialog = false
                    } else {
                        this.$message.error(res.result.message)
                    }
                }).finally(() => {
                    this.loadingDialog = false
                })
            }else{
                this.znData.abstractText_zh=this.znData.abstractText_zh
                this.znData.catalogue_zh=this.znData.catalogue_zh
                this.znData.title_zh=this.znData.title_zh
            }
            
        },
        handleClose() {
            this.dialogVisible = false
            this.record = {}
        },
        createImage() {
           
            html2canvas(this.$refs.content, {
                height: this.$refs.content.scrollHeight,
                width: this.$refs.content.scrollWidth,
                // allowTaint: true,
                useCORS: true
            }).then((canvas) => {
                const link = document.createElement("a"); // 创建一个超链接对象实例
                link.download = "Button.png"; // 设置要下载的图片的名称
                link.href = canvas.toDataURL(); // 将图片的URL设置到超链接的href中
                link.click(); // 触发超链接的点击事件
            });
        },
       
    }

}
</script>

<style lang="less" scoped>
//lang="less"样式预处理器，scoped当前页面有效
.page {
    width: 100%;
    background-color: #e9eef3;
    min-height: calc(100vh - 88px);
    position: relative;
    padding-top: 70px;

    .kf_button {
        position: fixed;
        right: 10px;
        bottom: 200px;
        background: #409EFF;
        width: 40px;
        height: 140px;
        z-index: 100;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        letter-spacing: 2px;
        color: #ffffff;
        writing-mode: vertical-rl;
        cursor: pointer;
        line-height: 20px;

        img {
            width: 30px;
            margin-top: 10px;
        }
    }

    .box-card {
        width: 100%;

        .search-section {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;

            .search-box {
                display: flex;
                align-items: center;
                margin-right: 20px;

                span {
                    flex-shrink: 0;
                }

                .search-content {
                    width: 100%;
                }
            }
        }



    }

    .bookInfor {
        width: calc(100% - 60px);
        padding: 30px;
        background-color: #c7e4ff;

        .inforBox {
            border: 1px solid #ffffff;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: top;

            .inforText {
                .inforItem {
                    margin-bottom: 10px;

                    .label {
                        flex-shrink: 0;
                        display: inline-block;
                        font-weight: bold;
                        margin-right: 5px;
                        text-align: right;
                    }
                }
            }

            .bookFace {
                margin-left: 20px;
            }
        }

        .introduction {
            margin-top: 30px;
            line-height: 2;

            .inforItem {
                .label {
                    font-weight: bold;
                }
            }
        }
    }

}

@media screen and(max-width: 759px) {
    .page {
        width: 100%;

        .box-card {
            width: 100%;
            min-height: 600px;

            .search-section {

                flex-wrap: wrap;

                .search-box {
                    margin-bottom: 15px;
                    width: 100%;

                    span {
                        display: inline-block;
                        width: 70px;
                        text-align: right;
                    }

                    .button {
                        width: 50%;
                    }
                }
            }



        }

        .bookInfor {
            width: calc(100% - 60px);
            padding: 30px;
            background-color: #c7e4ff;

            .inforBox {
                border: 1px solid #ffffff;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: top;

                .inforText {
                    .inforItem {
                        margin-bottom: 10px;

                        .label {
                            flex-shrink: 0;
                            display: inline-block;
                            font-weight: bold;
                            margin-right: 5px;
                            text-align: right;
                        }
                    }
                }

                .bookFace {
                    margin-left: 20px;
                }
            }

            .introduction {
                margin-top: 30px;
                line-height: 2;

                .inforItem {
                    .label {
                        font-weight: bold;
                    }
                }
            }
        }

    }
}


//vh把屏幕分100份1vh是1份
</style>
<style>
.el-table td,
.el-table th {
    text-align: center !important;
}
</style>