<template>
    <el-card class="head_wrap">
        <div class="head fr">
            <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                    欢迎您<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">个人中心</el-dropdown-item>
                    <el-dropdown-item command="2">修改密码</el-dropdown-item>
                    <el-dropdown-item command="3">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <p class="date fr">春城书籍搜索</p>
        <div class="clear"></div>
    </el-card>
</template>

<script>
// import { get } from "../utils/http"
import { removeToken } from "@/utils/auth"
export default {
    data() {
        return {
        }
    },
    //调接口
    mounted() {//组件挂在完毕执行内容
       
    },
    computed: {
    },
    methods: {
        handleCommand(command) {
            if (command == 3) {
                removeToken();
                this.$router.push('/login')
            }

        }
    }
}
</script>

<style lang="less" scoped>
.head_wrap {
    // line-height: 70px;
    position: fixed;
    top: 0;
    // background-color: #ffffff;
    width: 100%;
    z-index: 100;
}

.date {
    margin-right: 50px;
}

.head {
    margin-right: 30px;
}</style>